import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LanguagePreferenceProvider from "./Contexts/LnContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LanguagePreferenceProvider>
    <App />
  </LanguagePreferenceProvider>
);
